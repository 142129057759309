<template>
  <div class="ueex-home-page">
    <div class="home-content-one">
      <div class="content">
        <div class="left">
          <div class="home-sign">
            <div class="top">
              {{
                translateTitle(
                  "UEEX The World’s Leading Digital Wealth Management Platform"
                )
              }}
            </div>
            <!-- <div class="middle">
              {{ translateTitle("And Trading Platform") }}
            </div> -->
          </div>
          <div class="home-sign V2">
            {{
              translateTitle(
                "Instantly trade hundreds of digital assets and stock"
              )
            }}
          </div>
          <div class="dong-show">
            <div class="regsiter-content-top" v-show="!userInfoShow">
              <input
                v-model="resiterVal"
                type="text"
                :placeholder="translateTitle('输入邮箱/手机号码')"
              />
            </div>
            <span
              @click="onResiter(2)"
              class="goRegsiter theme-btn"
              v-show="!userInfoShow"
              >{{ translateTitle("注册") }}</span
            >
            <span
              class="go-trade theme-btn"
              @click="goTrade"
              v-show="userInfoShow"
              >{{ translateTitle("Trade Now") }}</span
            >
          </div>
        </div>
        <div class="right" style="cursor: pointer">
          <img src="../../assets/img/ueex/ueexbanner.png" />
        </div>
      </div>
    </div>
    <div class="trading">
      <!-- <div class="cont">
        <img src="../../assets/img/mask/sound.webp" class="svgsound" />
        {{ translateTitle("ZERO fees Spot trading") }}

        <div class="right">
          <span style="color: #666; cursor: pointer" @click="onClickMore">
            {{ translateTitle("See More") }}
          </span>
          <i class="el-icon-arrow-right imgcont"></i>
        </div>
      </div> -->
      <div class="tips-massage-content">
        <div class="left">
          <i class="iconfont iconxiaoxi"></i>
          <a :href="firstArticles.html_url" target="_blank">
            <span class="article-title">
              {{ firstArticles.title || "--" }}</span
            >
          </a>
        </div>
        <!-- <div class="left-driver"></div>
        <div class="bottom">
          <a :href="secondArticles.html_url" target="_blank">
            <span class="article-title">
              {{ secondArticles.title || "--" }}</span
            >
          </a>
        </div> 
        <div class="right-driver"></div>
      -->
        <div @click="onClickMore" class="right">
          <span>{{ translateTitle("See More") }}</span>
          <i class="iconfont icongengduo"></i>
        </div>
      </div>
      <swiper
        class="swiper-content"
        :pagination="{ clickable: true }"
        :options="swiperOptionTop"
      >
        <swiper-slide
          class="slide"
          v-for="(item, index) of imgList"
          :key="index"
          ><img
            class="swiper-img"
            @click="jumpToUrlV2(item)"
            :src="
              item.showtype ? item.src : item.language['img_url_' + language]
            "
            :data-href="
              item.showtype
                ? ''
                : item.language
                ? item.language[`url_${language}`]
                : item.param.url
            "
            alt=""
        /></swiper-slide>

        <div
          class="swiper-pagination"
          v-show="imgList.length > 4"
          slot="pagination"
        ></div>
        <div
          class="swiper-button-prev"
          v-show="imgList.length > 4"
          slot="button-prev"
        ></div>
        <div
          class="swiper-button-next"
          v-show="imgList.length > 4"
          slot="button-next"
        ></div>
      </swiper>
    </div>
    <div class="home-content-two" :class="imgList.length == 0 ? '' : ''">
      <div class="content-two-title">
        {{ translateTitle("市场行情") }}
      </div>
      <div class="quotes-content">
        <el-table
          v-loading="tableLoading"
          :data="tableDataComputed"
          row-key="symbol_id"
          style="width: 100%"
          class="trade-table"
          @row-click="onClickRow"
        >
          <el-table-column
            :label="translateTitle('币对')"
            align="left"
            min-width="140"
            prop="from_symbol"
          >
            <template slot-scope="{ row }">
              <!-- @click="onSymbolClick(row)" -->
              <span class="coin-content" style="cursor: pointer">
                <img class="coin-img" :src="row.coin_url" alt="" />
                <span
                  class="left-symbol"
                  style="margin-left: 10px; font-size: 16px"
                  >{{ row.from_symbol }}</span
                >
                <span class="dark-color"> / {{ row.to_symbol }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('最新价')"
            align="left"
            prop="usd"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro left-row">
                  {{ formatNumberRgx(row.last) }}
                </span>
                <span class="dark-color DINPro"
                  >/ {{ systemCurrencyMaps[systemCurrency]
                  }}{{ formatNumberRgx(row.currency[systemCurrency]) }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('24H涨跌幅')"
            align="center"
            prop="info.price_change_percent"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <span
                class="left-row DINPro"
                :class="Number(row.price_change_percent) > 0 ? 'green' : 'red'"
                ><span
                  class="green"
                  v-show="Number(row.price_change_percent) > 0"
                  style="margin-right: -3px"
                >
                  + </span
                >{{ row.price_change_percent }} %</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="`24H` + ' ' + translateTitle('交易量')"
            align="center"
            prop="info.price_change_percent"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <span class="left-row DINPro"
                >{{ formatNumberRgx(row.vol) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('行情')"
            align="center"
            prop="info.high"
            min-width="100"
          >
            <template slot-scope="{ row }">
              <img
                class="mrket-img"
                v-if="Number(row.price_change_percent) > 0"
                src="../../assets/img/markets/markt-up.png"
              />
              <img
                class="mrket-img"
                v-else
                src="../../assets/img/markets/markt-download.png"
              />
            </template>
          </el-table-column>

          <el-table-column
            :label="translateTitle('操作')"
            class="operate-column"
            align="right"
            min-width="90"
          >
            <template slot-scope="{ row }">
              <!-- @click="onSymbolClick(row)" -->
              <el-button @click="onSymbolClick(row)" class="operate">{{
                translateTitle("交易")
              }}</el-button>
            </template>
          </el-table-column>
          <no-data
            slot="empty"
            v-show="!tableLoading && tableDataComputed.length"
          ></no-data>
        </el-table>
        <div class="more-info">
          <div>
            <!-- <a :href="tgUrl" target="_blank">
              <span class="more-title more">{{
                translateTitle("MaskEX官方社聊链接")
              }}</span>
            </a>
            <i class="line"></i>
            <a :href="rateUrl" target="_blank">
              <span class="more-title more">{{
                translateTitle("费率标准")
              }}</span>
            </a>
            <i class="line"></i> -->
            <a :href="routeSplicing(language, 'markets')">
              <span @click="onLookMarket" class="more-title more">{{
                translateTitle("查看更多行情")
              }}</span
              ><i class="iconfont icongengduo"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="home-content-five">
      <p class="content-title V2">
        <span class="left">
          {{ translateTitle("UEEx last 24 hours trading volume") }}
        </span>
        <span class="right">
          {{ translateTitle("$ 1,888,888,999") }}
        </span>
      </p>
      <div class="five-content">
        <div class="item-content">
          <img src="../../assets/img/ueex/bg-1.png" alt="" />
          <span class="item-text">
            {{ translateTitle("User Country Distribution") }}
          </span>
        </div>
        <div class="item-content V2">
          <img src="../../assets/img/ueex/bg-2.png" alt="" />
          <span class="item-text">
            {{ translateTitle("Number of User") }}
          </span>
        </div>
        <div class="item-content V2">
          <img src="../../assets/img/ueex/bg-3.png" alt="" />
          <span class="item-text">
            {{ translateTitle("Trading Pairs") }}
          </span>
        </div>
        <div class="item-content V2">
          <img src="../../assets/img/ueex/bg-4.png" alt="" />
          <span class="item-text">
            {{ translateTitle("Service Support") }}
          </span>
        </div>
      </div>
    </div>
    <div class="home-content-four">
      <p class="content-title">
        {{
          translateTitle(
            "Anytime, Anywhere, Multi-Platforms Terminal Transaction"
          )
        }}
      </p>
      <!-- <p class="content-title-v2">
        <span>
          {{ translateTitle("多终端完美兼容，随时满足各种场景的交易需求。") }}
        </span>
      </p> -->
      <div class="four-content">
        <div class="left-content">
          <p class="left-content-title">
            {{
              translateTitle(
                "Multi-terminals are perfectly compatible to meet the transaction needs of various scenarios at any time."
              )
            }}
          </p>
          <ul class="tips-details">
            <li class="">
              <i class="el-icon-success-1"></i>
              <span>{{ translateTitle("随时查看实时行情") }}</span>
            </li>
            <li>
              <i class="el-icon-success-1"></i>
              <span>{{
                translateTitle("轻松买卖BTC，ETH，DOGE和其他数字资产")
              }}</span>
            </li>
            <li>
              <i class="el-icon-success-1"></i>
              <span>{{
                translateTitle("获取您关注的加密货币价格变化预警")
              }}</span>
            </li>
            <li>
              <i class="el-icon-success-1"></i>
              <span>
                {{ translateTitle("查看比特币现货、期货、期权价格") }}</span
              >
            </li>
            <li>
              <i class="el-icon-success-1"></i>
              <span>{{ translateTitle("比较各交易所加密货币价格") }}</span>
            </li>
          </ul>
          <div class="download-content">
            <div class="download-item">
              <a :href="appstore_url" target="_blank" rel="noopener noreferrer">
                <img src="../../assets/img/ueex/ueex-app-store.png" alt="" />
              </a>
            </div>
            <div class="download-item">
              <a
                href="https://play.google.com/store/apps/details?id=com.ueasy8.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="../../assets/img/ueex/mask-google-play.png" alt="" />
              </a>
            </div>

            <div
              class="download-item qr-code"
              @mouseenter="onShowDownLoad"
              @mouseleave="onHiddenDownLoad"
            >
              <img src="../../assets/img/ueex/ueex-down-btn.png" alt="" />
            </div>
          </div>
          <div v-show="showDownLoadImg" class="download-code">
            <img class="qr_code_canvas" :src="appDownLoadImg" alt="" />
            <div class="scren-code">{{ translateTitle("扫码下载") }}</div>
          </div>
        </div>
        <div class="right-content">
          <img src="../../assets/img/ueex/ueexbanner1.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 成交量介绍 -->
    <div class="home-content-three">
      <div class="point">
        <div class="point-item">
          <img src="../../assets/img/ueex/point-1.png" alt="" />
          <p>{{ translateTitle("Quickly Buy Digital Currency") }}</p>
          <span>{{
            translateTitle(
              "Use a variety of payment methods to trade digital currency, safe and fast"
            )
          }}</span>
        </div>
        <div class="point-item V2">
          <img src="../../assets/img/ueex/point-2.png" alt="" />
          <p>{{ translateTitle("Optimal Transaction Fee Rate") }}</p>
          <span>{{
            translateTitle(
              "Preferential transaction rates, competitive VIP rights, and enjoy the best quality service"
            )
          }}</span>
        </div>
        <div class="point-item">
          <img src="../../assets/img/ueex/point-3.png" alt="" />
          <p>{{ translateTitle("Reliable Security") }}</p>
          <span>{{
            translateTitle(
              "Our sophisticated security measures and SAFU fund protect your digital assets from all risks"
            )
          }}</span>
        </div>
      </div>
    </div>
    <div class="home-content-partners">
      <div class="hcp-title">
        <p class="ht-main">
          <!-- Our partners -->
          {{ translateTitle("我们的合作伙伴") }}
        </p>
      </div>
      <div class="hcp-content">
        <div class="hc-imgs">
          <img class="img-c" src="../../assets/img/ueex/Partner_CMC.svg" />
        </div>
        <div class="hc-imgs V2">
          <img
            class="img-c"
            src="../../assets/img/ueex/Partner_Feixiaohao.svg"
          />
        </div>
        <div class="hc-imgs V2">
          <img class="img-c" src="../../assets/img/ueex/Partner_Slowmist.svg" />
        </div>
        <div class="hc-imgs V2">
          <img class="img-c" src="../../assets/img/ueex/Partner_Sumsub.svg" />
        </div>
        <div class="hc-imgs V2">
          <img class="img-c" src="../../assets/img/ueex/Partner_UPay.svg" />
        </div>
      </div>
    </div>
    <div v-show="!userInfoShow" class="home-content-six">
      <div class="six-content">
        <p class="six-content-title">
          {{ translateTitle("Start Your Digital Currency Journey Now!") }}
        </p>
        <p class="six-content-title V2">
          {{
            translateTitle(
              "Create your account in less than 10 seconds, open 20X margin trading, 100X contract trading NOW!"
            )
          }}
        </p>

        <div class="regsiter-content">
          <input
            v-model="resiterVal"
            type="text"
            :placeholder="translateTitle('输入邮箱/手机号码')"
          />
          <span @click="onResiter(2)" class="goRegsiter theme-btn">{{
            translateTitle("注册")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import Socket from "@/assets/js/socket.js";
import { floorNumber, ceilNumber } from "@/assets/js/digital";
import { apiGetStaticTableInfo } from "@/model/settings";
import NoData from "@/components/common/NoData";
import Cookies from "js-cookie";
import { apiGetBannerList, apiChannelPartnerList } from "@/model/home/index";
import { mapActions, mapGetters } from "vuex";
import { apiWsUrl, apiGetSpotList } from "@/model/exchange/index";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import axios from "axios";
var vm = null;
import { VUE_APP_ID, VUE_APP_APP } from "@/assets/js/stationSetting";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  components: {
    NoData,
  },
  data() {
    return {
      tt: {}, //WS延时触发器
      lockReconnect: false, //避免重复连接
      wsContentTime: 0,
      swiperOptionTop: {
        slidesPerView: 4,
        spaceBetween: 15,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      detailsUrl: "",
      systemCurrencyMaps,
      token: "",
      wsUrl: "",
      appDownLoadImg: "",
      activeName: "1",
      activeLi: "2",
      // realIndex:0,
      sideLen: 4,
      isResiter: false,
      resiterVal: "",
      resiterValue: "",
      isMobile: false,
      isEmail: false,
      showDownLoadImg: false,
      android_url: "",
      appstore_url: "",
      doanGoogleUrl: "https://www.topcreditbank.com/app_download/",
      doanIosUrl: "https://apps.apple.com/app/id1525081932",
      tipUrl: "",

      firstArticles: {},
      secondArticles: {},
      swiperOption: {
        initialSlide: 0,

        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
        on: {
          click: function () {
            // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
            // console.log(this); // -> Swiper
            // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
            const realIndex = this.realIndex;
            vm.handleClickSlide(realIndex);
          },
        },

        // slidesPerView: 1,
        spaceBetween: 24,
        slidesPerView: 4,
        loopedSlides: 8,
        slidesPerGroup: 1,
        // slidesPerView: 'auto',
        // // spaceBetween: 10,
        // // slidesPerView: "auto",
        // loopedSlides: 1,
        // slidesPerGroup: 'auto',
        loopFillGroupWithBlank: true,
        infinite: 1, // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 4000,
        },
        autoplayDisableOnInteraction: false,
        // //循环
        loop: true,
        // // //每张播放时长3秒，自动播放
        // autoplay: 2000,
        // // //滑动速度
        // speed: 1000,
      },
      oldImgList: [
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-1.png"),
            img_url_en: require("../../assets/img/home/banner-1.png"),
            img_url_ja: require("../../assets/img/home/banner-1.png"),
            img_url_ko: require("../../assets/img/home/banner-1.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-1.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-1.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-2.png"),
            img_url_en: require("../../assets/img/home/banner-2.png"),
            img_url_ja: require("../../assets/img/home/banner-2.png"),
            img_url_ko: require("../../assets/img/home/banner-2.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-2.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-2.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-3.png"),
            img_url_en: require("../../assets/img/home/banner-3.png"),
            img_url_ja: require("../../assets/img/home/banner-3.png"),
            img_url_ko: require("../../assets/img/home/banner-3.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-3.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-3.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-4.png"),
            img_url_en: require("../../assets/img/home/banner-4.png"),
            img_url_ja: require("../../assets/img/home/banner-4.png"),
            img_url_ko: require("../../assets/img/home/banner-4.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-4.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-4.png"),
          },
          param: { url: "" },
        },
      ],
      imgList: [],
      articlesList: [],
      tableDataComputed: [],
      exchangeCoinMap: {},
      coinInfoList: {},
      tableLoading: true,
      showDownLoadImgV2: false,
      tableData: [],
      tempMarkList: [],
      activeSymbol: "1",
      collectSymbol: [22],
      ws: null,
      tgUrl: "",
      rateUrl: "",
      timer: null,
      currentVideo: "https://static.aws-s1.com/v/MaskExIFX2023.m4v",
      voucherIntervalV2: null,
      partnerList: [],
    };
  },
  mounted() {
    this.voucherIntervalV2 = setInterval(() => {
      document.title = "UEEx: The Best Crypto and Stock Exchange Platform";
    }, 800);
  },
  async created() {
    this.getPartnerList();
    await this.getExchangeCoinMap();
    let qrcode_download = JSON.parse(sessionStorage.getItem("qrcode_download"));
    let about = JSON.parse(sessionStorage.getItem("about"));
    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    this.appDownLoadImg = qrcode_download.url;

    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
      this.detailsUrl =
        zendesk_notice.language[`articles_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
      this.detailsUrl = zendesk_notice.articles_url;
    }

    let othersList = JSON.parse(sessionStorage.getItem("others"));
    this.rateUrl = othersList[0].url;
    let qrcode = JSON.parse(sessionStorage.getItem("qrcode"));
    this.tgUrl = qrcode.ori_url;
    this.getBannerData();
    await this.getStaticTableInfo(1);
    this.getDeatilsData();
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      await this.initURL();
      this.initWS();
    } else {
      this.token = Cookies.get("topcredit_exchange_t");
      await this.initURL();
      this.initWS();
    }
    vm = this;
    // await this.getItemList();
    this.getTradePairList();
    this.voucherInterval = setInterval(() => {
      // this.getBannerData();
      this.getDeatilsData();
    }, 5000 * 60 * 6);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
    clearInterval(this.voucherIntervalV2);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
    clearInterval(this.voucherIntervalV2);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),

    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },

  methods: {
    routeSplicing,
    floorNumber,
    jumpToBolg() {
      window.open(``, "_blank");
    },
    async getPartnerList() {
      const { status, data } = await apiChannelPartnerList();
      if (status == 200) {
        this.partnerList = data;
      }
    },
    jumpToUrlV2(item) {
      if (item.language) {
        window.open(item.language[`url_${this.language}`]);
      } else {
        window.open(item.param.url);
      }
    },
    onVideoClick(type) {
      this.activeLi = type;
      if (type == 1) {
        this.currentVideo = "https://static.aws-s1.com/v/AppBenefit.mp4";
      } else if (type == 2) {
        this.currentVideo = "https://static.aws-s1.com/v/MaskExIFX2023.m4v";
      } else {
        this.currentVideo =
          "https://static.aws-s1.com/v/CopyTrading_1920x1080.mp4";
      }
    },
    // onRegisterClick() {
    //   this.$router.push(`/register/${this.language}`);
    // },
    async getExchangeCoinMap() {
      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (data[key].symbol_type == 1 && data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObj));
      }
    },

    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
        this.android_url = data.android_url;
        this.appstore_url = data.appstore_url;
      }
    },
    handleClick() {},
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     sessionStorage.setItem("itemList", JSON.stringify(data));
    //   }
    // },
    onResiter(type) {
      var name = "";
      var inputVal = "";
      if (type == 1) {
        inputVal = this.resiterValue;
      } else {
        inputVal = this.resiterVal;
      }
      var emailReg = new RegExp(
        "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
      ); //正则表达式
      var numReg = /^\d{9,}$/;
      if (inputVal) {
        if (emailReg.test(inputVal)) {
          name = "email";
        } else if (numReg.test(inputVal)) {
          name = "phone";
        } else {
          name = "account";
        }
      } else {
        name = "";
        inputVal = "";
      }
      this.$router.push({
        name: "Register",
        params: {
          name: name,
          val: inputVal,
        },
      });
    },
    handleClickSlide(index) {
      console.log("當前點擊索引：", index);
    },
    onClickMore() {
      window.open(this.tipUrl);
    },
    onLookMarket() {
      //   this.$router.push(this.routeSplicing(this.language, "markets"));
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },
    onShowDownLoadV2() {
      this.showDownLoadImgV2 = true;
    },
    onHiddenDownLoadV2() {
      this.showDownLoadImgV2 = false;
    },

    async getBannerData() {
      const req = {
        type: 1,
        title: "main",
      };
      const { status, data } = await apiGetBannerList(req);
      if (status == 200) {
        let len = data.length;
        // let len = 1;
        let arr = [...data];
        // let arr = [data[0]];
        if (len < 4) {
          for (let i = 0; i < 4 - len; i++) {
            arr.push({
              showtype: 1,
              src: require("../../assets/img/mask/banner-maskex" +
                (i + 1) +
                ".webp"),
            });
          }
          this.imgList = arr;
        } else {
          this.imgList = data;
        }
        // this.sideLen = len >= 4 ? 4 : len;

        // this.imgList = arr;
      }
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },

    async getDeatilsData() {
      var that = this;
      axios
        .get(this.detailsUrl)
        .then(function (response) {
          that.articlesList = response.data.articles;
          that.firstArticles = response.data.articles[0];
          that.secondArticles = response.data.articles[1];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getTradePairList() {
      apiGetSpotList().then((res) => {
        if (res.status == 200) {
          let tempArr = res.data;
          tempArr.forEach((ele) => {
            ele.lastV2 = Number(ele.last);
            ele.vol = floorNumber(ele.vol, 2);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.exchangeCoinMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[0]
                  .toUpperCase();
                ele["to_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[1]
                  .toUpperCase();
                ele["info"] = this.exchangeCoinMap[key];
                ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
                this.tempMarkList.push(ele);
              }
            }
            for (const key in this.coinInfoList) {
              if (ele.from_symbol) {
                if (
                  this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
                ) {
                  ele.coin_url = this.coinInfoList[key].coin_url;
                }
              }
            }
          });
          this.tableLoading = false;
          // this.tableDataComputed = this.tempMarkList;
          let tempList = this.tempMarkList.filter((ele) => {
            return ele.from_symbol != undefined && ele.web_recommend == 1;
          });
          this.tableDataComputed = tempList.sort(
            this.compareOrderBy("order_by")
          );
        }
      });
    },
    initWS() {
      let me = this;
      this.tableLoading = true;
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: VUE_APP_ID,
          },
        },
        callback: this.onResponse,
        onConnect,
      };
      window.USDT_WS = this.ws = new Socket(option).getInstance();
      //   setTimeout(() => {}, 1200);
      me.global.setWs(this.ws);
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWS();
          me.lockReconnect = false;
        }, 3000);
      }
      // this.getSubData();
    },
    onResponse(data) {
      this.tableLoading = false;
      if (data.action == "Pushdata.marketM") {
        this.tableData = data.data;

        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach((ele) => {
          ele.vol = floorNumber(ele.vol, 2);
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[0]
                .toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[1]
                .toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
              ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (
                this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
              ) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        let tempList = this.tableData.filter((ele) => {
          return ele.from_symbol != undefined && ele.web_recommend == 1;
        });

        this.tableDataComputed = tempList.sort(this.compareOrderBy("order_by"));
      }
    },
    compareOrderBy(property) {
      return function (a, b) {
        if (
          a &&
          b &&
          a["info"] &&
          b["info"] &&
          a["info"][property] &&
          b["info"][property]
        ) {
          let value1 = a["info"][property];
          let value2 = b["info"][property];
          if (value1 - value2 != 0) {
            return value1 - value2;
          } else {
            let temp1 = a["from_symbol"];
            let temp2 = b["from_symbol"];
            if (temp1 < temp2) {
              // 按某种排序标准进行比较, a 小于 b
              return -1;
            }
            if (temp1 > temp2) {
              return 1;
            }
            return 0;
          }
        } else {
          return 0;
        }
      };
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
    },
    onSymbolClick(row) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },

    goTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
    },
    onClickRow(row, column, event) {
      // let tempUrl = this.routeSplicing(
      //   this.language,
      //   `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      // );
      // window.open(tempUrl);
    },
  },
};
</script>
<style lang="scss">
.ar .ueex-home-page .home-content-five .five-items-content .five-content-items {
  padding: 35px 50px 0 0;
}
.en .ueex-home-page .home-content-one .home-sign {
  top: 54px;
  .top {
    // font-family: DIN;
    font-family: "Plus Jakarta Sans";
    font-size: 38px;
    font-weight: bold;
  }
  .middle {
    // font-family: DIN;
    font-size: 43px;
    font-weight: bold;
  }
}
@keyframes a {
  0% {
    transform: translate(0);
  }

  to {
    transform: translateY(-12px);
  }
}
@keyframes b {
  0% {
    transform: translateY(0);
  }
  // 50% {
  //   transform: translateY(100%);
  // }
  100% {
    transform: translateY(100%);
  }
}
@keyframes bounce-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
.ar
  .ueex-home-page
  .home-content-five
  .five-content
  .details
  .left
  .text-item
  .pointer {
  margin-right: 0;
  margin-left: 20px;
}
.ar .ueex-home-page .home-content-two {
  direction: ltr !important;
}
.zh_CN {
  //   .ueex-home-page .home-content-one .home-sign {
  //     display: flex;
  //     justify-content: center;
  //     top: 90px;
  //   }
}
.zh_CN {
  //   .ueex-home-page .home-content-one .home-sign {
  //     display: flex;
  //     justify-content: center;
  //     top: 90px;
  //   }
}
.zh_HK {
  //   .ueex-home-page .home-content-one .home-sign {
  //     display: flex;
  //     justify-content: center;
  //     top: 90px;
  //   }
}
.ar .ueex-home-page .home-content-four .left-content .tips-details li i {
  margin-right: 0;
  margin-left: 6px;
}
.ar .ueex-home-page .regsiter-content input {
  margin: 0 0 0 10px;
  padding: 12px 17px 12px 0;
}
.ar {
  .ueex-home-page
    .home-content-five
    .five-items-content
    .five-content-items
    .text-title {
    text-align: right;
  }
  .ueex-home-page
    .home-content-five
    .five-items-content
    .five-content-items
    .text-item
    .pointer {
    margin-right: 0;
    margin-left: 20px;
  }
  .ueex-home-page .home-content-five .five-content .details .right {
    direction: ltr !important;
  }
  .ueex-home-page .el-table tbody .cell .coin-content {
    // float: right;
    margin-right: 80px;
    .coin-img {
      margin-left: 10px;
    }
  }

  .ueex-home-page .home-content-four .left-content .tips-details li i {
    margin-left: 6px;
    margin-right: 0;
  }
  .ueex-home-page .home-content-four .left-content .download-content {
    direction: ltr;
  }
  .ueex-home-page .home-content-four .left-content .download-code {
    height: 160px;
    .qr_code_canvas {
      margin-right: 0;
    }
  }
}
.ueex-home-page {
  margin-top: -2px;
  // height: 2977px;
  display: block;
  background: #f4f4f5;
  // user-select: none;
  padding: 0;
  flex: 1;
  [v-cloak] {
    display: none;
  }
  .fiv-svg {
    position: relative;
    i {
      z-index: 900;
      position: absolute;
      font-size: 60px;
      top: -2px;
      left: 110px;
    }
  }
  .hor-line {
    width: 74px;
    height: 6px;
    border-radius: 3px;
    background-image: linear-gradient(89deg, #00cf88 0%, #0084d6 100%);
    margin: 0 auto;
    display: block;
  }
  // font-family: PingFangSC, BinancePlex, Arial, sans-serif !important;
  .is-resiger {
    height: 340px;
  }
  .no-resiger {
    height: 340px;
  }

  .swiper-img {
    width: 100%;
    height: 144px;
    border-radius: 6px;
  }

  .home-content-one {
    background: #f4f4f5;

    .content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
    }
    .left {
      width: 50%;
      text-align: left;
      margin-top: 104px;
      min-height: 400px;
    }
    .right {
      img {
        width: 658px;
        height: 407px;
        margin-left: 50px;
        margin-top: 57px;
      }
    }
    min-height: 400px;
    // img {
    //   height: 400px;
    // }
    .coin-ico {
      position: absolute;
      // animation: b 1.5s ease-in-out 2s infinite alternate;

      &.usdt {
        animation: bounce-down 1s ease-in-out 2s infinite alternate;
        bottom: 10%;
        right: 20%;
      }
      &.btc {
        animation: bounce-down 1.3s ease-in-out 2s infinite alternate;
        top: 5%;
        right: 24%;
      }
      &.eth {
        // animation: b 0.5s ease-in-out 0.5s infinite alternate;
        animation: bounce-down 1.6s ease-in-out 0.5s infinite alternate;
        bottom: 20%;
        left: 15%;
      }
    }
    .home-sign {
      font-size: 40px;
      font-weight: normal;
      text-align: left;
      color: #15192a;
      width: 100%;
      .top {
        font-size: 40px;
        font-weight: 600;
      }
      .middle {
        font-weight: bolder;
      }
      &.V2 {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 400;
        top: 170px;
        color: #18181b;
      }
    }
    .register-btn {
      cursor: pointer;
      height: 54px;
      padding: 13px 150px;
      border-radius: 50px;
      background-image: linear-gradient(87deg, #00cf88 0%, #0084d6 100%);
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 60px;
      left: calc(50% - 210px);
    }
  }
  .home-content-two {
    min-height: 602px;
    background: #f4f4f5;
    padding-top: 40px;
    padding-bottom: 40px;
    &.V2 {
      min-height: 588px;
    }

    .tips-massage-content {
      padding: 20px 0px;
      font-size: 14px;
      color: #080c34;
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        // min-width: 265px;
        // width: auto;
        i {
          font-size: 16px;
          color: #080c34;
        }
      }
      .left-driver {
        width: 1px;
        height: 18px;
        margin: 2px 15px 0;
        background-color: #c2c7d0;
      }

      .bottom {
        // min-width: 200px;
        // margin-left: 15px;
        text-align: left;
      }
      .article-title {
        color: #080c34 !important;
        min-width: 250px;
      }
      .right-driver {
        width: 1px;
        height: 18px;
        margin: 2px 20px 0 15px;
        background-color: #c2c7d0;
      }
      .right {
        cursor: pointer !important;
        i {
          font-size: 14px;
          color: #9aa1b1;
          margin-top: 2px;
          &:hover {
            color: #3a7dff;
          }
        }
        &:hover {
          color: #3a7dff;
          i {
            color: #3a7dff;
          }
        }
      }
    }
    .content-two-title {
      width: 1200px;
      margin: 0 auto;
      font-family: DINPro;
      font-size: 36px;
      font-weight: bold;
      color: #15192a;
      margin-bottom: 30px;
    }
    .quotes-content {
      width: 1200px;
      // height: 572px;
      margin: 0 auto;
      // border-radius: 6px;
      // box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
      .left-symbol {
        color: #354052;
        font-size: 18px;
        font-weight: 500;
      }
      .left-row {
        color: #354052;
        font-size: 18px;
        font-weight: 600;
      }
      .el-table th,
      .el-table tr {
        background: #f4f4f5;
      }
      .el-table td,
      .el-table th.is-leaf {
        border-bottom: none;
      }
      .el-table th.is-right .cell {
        padding-right: 40px;
      }
      .el-table {
        tr {
          cursor: pointer;
        }
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f1f5f6;
      }
      .more-info {
        display: flex;
        align-items: center;
        height: 72px;
        justify-content: center;
        text-align: center;
        font-size: 16px;
        color: #7f8490;
        .more-title {
          width: 102px;
          vertical-align: middle;
          cursor: pointer;
        }
        .line {
          width: 1px;
          height: 18px;
          vertical-align: middle;
          display: inline-block;
          margin: 2px 18px;
          background-color: #c2c7d0;
        }
        .more {
          cursor: pointer;
          vertical-align: middle;
          &:hover {
            color: #3a7dff;
            span {
              color: #3a7dff;
            }
          }
        }
        span {
          color: #9aa1b1;
        }
        .icongengduo {
          color: #7f8490;
          font-size: 14px;
          vertical-align: sub;
        }
      }

      .el-table {
        background: #f4f4f5;
        .el-icon-star-off {
          cursor: pointer;
        }
      }
      .el-table::before {
        height: 0;
      }
    }
  }
  .home-content-three {
    background: #f4f4f5;
    text-align: center;
    height: 350px;
    margin-bottom: 100px;
    .point {
      display: flex;
      align-items: center;
      justify-content: center;

      &-item {
        display: flex;
        flex-direction: column;
        width: 389px;
        height: 358px;
        border-radius: 8px;
        background: #fff;
        padding: 16px 32px 32px 32px;
        img {
          width: 140px;
          height: 140px;
          margin: 0 auto 20px;
        }
        &.V2 {
          margin: 0 16px;
        }
        p {
          color: #f28c34;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
          text-align: center;
          margin: 0 auto;
          width: 280px;
          height: 64px;
          line-height: 32px;
        }

        span {
          font-size: 16px;
          font-weight: 400;
          color: #15192a;
          line-height: 24px;
          margin-top: 10px;
        }
      }

      &-item:last-child {
        margin-right: 0;
      }
    }
  }
  .home-content-four {
    height: 700px;
    text-align: center;
    padding-bottom: 50px;
    background: #f4f4f5;
    .four-content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      // justify-content: space-between;
    }
    .content-title-v2 {
      width: 1200px;
      font-family: DINPro;
      margin: 0 auto;
      text-align: left;
      margin-bottom: 38px;
      span {
        display: block;
        width: 1200px;
        font-size: 18px;
        color: #15192a;
      }
    }
    .content-title {
      font-size: 36px;
      font-weight: 600;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding: 60px 0;
      font-family: DINPro;
      color: #354052;
    }
    .hor-line {
      margin-bottom: 50px;
    }
    .left-content {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      position: relative;
      width: 520px;
      // min-width: 420px;
      // max-width: 500px;
      // margin-left: 80px;
      .tips-details {
        li {
          display: flex;
          i {
            margin-right: 16px;
            color: #f28c34;
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #f28c34;
            vertical-align: middle;
            border-radius: 50%;
            margin-top: 7px;
          }
        }
      }

      .left-content-title {
        font-size: 18px;
        font-weight: 600;
        // width: 20px;
        color: #1f2533;
        margin-bottom: 25px;
      }
      li {
        margin-bottom: 14px;
        color: #354052;
      }
      img {
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
      .download-content {
        margin-top: 40px;
        display: flex;
        position: relative;
        .download-item {
          img {
            width: 150px;
            height: 50px;
          }
        }
        .qr-code {
          img {
            cursor: pointer;
            text-align: center;
            width: 50px;
            height: 50px;
            color: #ffffff;
          }
        }
      }
      .download-code {
        // float: right;
        // margin-right: 60px;
        position: absolute;
        right: 100px;
        box-sizing: border-box;
        width: 120px;
        height: 145px;
        border-color: #eaecef;
        margin-top: 10px;
        background: #ffffff;
        padding: 10px;
        border-radius: 4px;
        z-index: 900;

        img {
          width: 100px;
          height: 100px;
        }
        .scren-code {
          width: 100%;
          margin-top: 5px;
          text-align: center;
          box-sizing: border-box;
          color: #1e2329;
          font-size: 14px;
        }
      }
    }
    .right-content {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 630px;
      height: 370px;
      img {
        width: 548px;
        height: 466px;
      }
    }
  }
  .home-content-five {
    // height: 370px;
    padding: 0px 0 60px;
    background: #f4f4f5;
    .content-title {
      width: 1200px;
      font-family: DINPro;
      font-size: 36px;
      font-weight: 600;
      text-align: left;
      padding-top: 65px;
      margin: 0px auto 22px;
      color: #1f2533;
      .right {
        float: right;
      }
      &.V2 {
        direction: ltr;
      }
      &.V3 {
        margin-bottom: 58px;
      }
    }
    .hor-line {
      margin-bottom: 50px;
    }
    .five-content {
      display: flex;
      justify-content: center;

      .item-content {
        position: relative;
        &.V2 {
          margin-left: 16px;
        }
        img {
          width: 288px;
          height: 288px;
        }
        .item-text {
          position: absolute;
          bottom: 30px;
          left: 0px;
          color: #fff;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          display: inline-block;
          width: 288px;
          text-align: center;
        }
      }
    }
    .five-items-content {
      width: 1200px;
      margin: 0 auto;
      .five-content-items {
        display: inline-block;
        width: 400px;
        height: 280px;
        border: 1px solid #edf1f6;
        padding: 35px 0 0 50px;
        vertical-align: top;

        &.V1 {
          border-right: none;
          border-bottom: none;
        }
        &.V2 {
          border-right: none;
          border-bottom: none;
        }
        &.V3 {
          border-bottom: none;
        }
        &.V4 {
          border-right: none;
        }
        &.V5 {
          border-right: none;
        }
        .right {
          img {
            margin-left: 30px;
            // width: 52px;
            height: 58px;
          }
        }

        .text-title {
          width: 300px;
          font-size: 26px;
          font-weight: bold;
          color: #15192a;
          margin: 20px 0 20px 30px;
          text-align: left;
        }
        .text-item {
          margin-bottom: 22px;
          .pointer {
            border-radius: 50%;
            width: 7px;
            height: 7px;
            margin-right: 20px;
            background-color: #32c8a4;
            display: inline-block;
          }
          span {
            display: inline-block;
            width: 212px;
            font-size: 14px;
            vertical-align: top;
            color: #666;
          }
        }
      }
    }
  }
  .home-content-six {
    text-align: center;
    background: #f4f4f5;
    padding-bottom: 66px;
    .six-content {
      width: 100%;
    }
    .six-content-title {
      text-align: center;
      color: #18181b;
      font-size: 36px;
      font-weight: 600;
      &.V2 {
        font-family: "Plus Jakarta Sans";
        margin-top: 16px;
        color: #18181b;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
      }
    }
    .regsiter-content {
      margin: 42px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        display: inline-block;
        width: 370px;
        height: 46px;
        margin: 0 10px 0 0;
        font-size: 16px;
        line-height: 46px;
        font-weight: 500;
        padding: 12px 0 12px 17px;
        border-radius: 2px;
        background-color: #fff;
      }
      .goRegsiter {
        border-radius: 2px;
        cursor: pointer;
        vertical-align: middle;
        background: #f28c34;
        text-align: center;
        font-size: 16px;
        display: inline-block;
        width: 130px;
        line-height: 46px;
        height: 46px;
        color: #fff;
        box-sizing: border-box;
        margin-top: 0;
      }
    }
  }
  .text-content {
    font-size: 16px;
    bottom: 83px;
    color: #333;
    padding-bottom: 3px;
    text-decoration: underline;
  }
  .dong-show {
    display: flex;
    position: relative;
    .dong a {
      margin-left: 12px;
    }
    .download-item {
      margin-left: 12px;
    }
    .download-code {
      // float: right;
      // margin-right: 60px;
      position: absolute;
      right: 0px;
      box-sizing: border-box;
      width: 120px;
      height: 145px;
      border-color: #eaecef;
      margin-top: 10px;
      background: #ffffff;
      padding: 10px;
      border-radius: 4px;
      z-index: 900;

      img {
        width: 100px;
        height: 100px;
      }
      .scren-code {
        width: 100%;
        margin-top: 5px;
        text-align: center;
        box-sizing: border-box;
        color: #1e2329;
        font-size: 14px;
      }
    }
  }
  .dong {
    min-width: 350px;
    white-space: nowrap;
    height: 70px;
    margin-top: 43px;
    span {
      display: inline-block;
      cursor: pointer;
      margin-right: 15px;
    }
    img.link {
      width: 52px;
      height: 52px;
    }
  }
  .regsiter-content-top {
    margin: 24px 0px;
    background: white;
    display: flex;
    border-radius: 12px;
    box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.06);
    padding: 0px 3px;
    justify-content: space-between;
    align-items: center;
    position: relative;

    input {
      display: inline-block;
      width: 350px;
      height: 50px;
      margin: 0 10px 0 0;
      font-size: 16px;
      line-height: 50px;
      font-weight: 500;
      padding: 12px 0px 12px 17px;
      border-radius: 2px;
      background-color: #ffffff;
      &::placeholder {
        color: #dddee1;
      }
    }
  }
  .go-trade {
    border-radius: 10px;
    cursor: pointer;
    vertical-align: middle;
    background: #f28c34;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    width: 520px;
    line-height: 50px;
    height: 50px;
    color: white;
    box-sizing: border-box;
    margin-right: 5px;
    margin-top: 24px;
  }
  .goRegsiter {
    border-radius: 10px;
    cursor: pointer;
    vertical-align: middle;
    background: #f28c34;
    text-align: center;
    font-size: 16px;
    display: inline-block;
    width: 92px;
    line-height: 50px;
    height: 50px;
    color: white;
    box-sizing: border-box;
    margin-right: 5px;
    margin-top: 24px;
    margin-left: 16px;
  }
  .trade-btn {
    border-radius: 10px;
    cursor: pointer;
    vertical-align: middle;
    background: #f28c34;
    text-align: center;
    font-size: 16px;
    display: inline-block;
    width: 92px;
    line-height: 50px;
    height: 50px;
    color: white;
    box-sizing: border-box;
    margin-top: 24px;
    margin-left: 16px;
  }
  .trading {
    width: 1200px;
    background: #f4f4f5;
    margin: 30px auto;
    .cont {
      img {
        width: 20px;
        float: left;
        margin-right: 5px;
        margin-bottom: 20px;
      }
    }
    font-size: 14px;
    color: #666;
    .right {
      float: right;
    }
    .swiper-content {
      width: 1200px;
      position: relative;
      .slide {
        width: 288px !important;
      }
      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-family: swiper-icons;
        font-size: 10px;
        font-weight: 900;
        text-transform: none !important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;
      }
      .swiper-button-prev,
      .swiper-button-next {
        &:hover {
          background-color: #15beb4;
        }
        color: #fff;
        background-color: #000;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
      .swiper-pagination {
        position: relative !important;
        margin-top: 15px;
        span {
          background-color: #c3c3c3;
          //   margin: 0px 10px;
          // color: #9aa1b1;
        }
        .swiper-pagination-bullet-active {
          opacity: 1;
          border-color: #15beb4;
          width: 20px;
          border-radius: 5px;
          font-size: #15beb4;
          background-color: #15beb4;
          text-align: center;
          color: #fff;
        }
      }
    }

    .tips-massage-content {
      padding: 20px 0px;
      font-size: 14px;
      color: #080c34;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        // min-width: 265px;
        // width: auto;
        i {
          font-size: 16px;
          color: #080c34;
        }
      }
      .left-driver {
        width: 1px;
        height: 18px;
        margin: 2px 15px 0;
        background-color: #c2c7d0;
      }

      .bottom {
        // min-width: 200px;
        // margin-left: 15px;
        text-align: left;
      }
      .article-title {
        color: #080c34 !important;
        min-width: 250px;
      }
      .right-driver {
        width: 1px;
        height: 18px;
        margin: 2px 20px 0 15px;
        background-color: #c2c7d0;
      }
      .right {
        cursor: pointer !important;
        i {
          font-size: 14px;
          color: #9aa1b1;
          margin-top: 2px;
          &:hover {
            color: #3a7dff;
          }
        }
        &:hover {
          color: #3a7dff;
          i {
            color: #3a7dff;
          }
        }
      }
    }
  }
  .el-table {
    margin-left: -10px;
    .el-table__header {
      thead {
        .cell {
          font-size: 14px;
          color: #7f8490;
        }
      }
    }
    tbody {
      .operate {
        width: 88px;
        height: 30px;
        padding: 4px 0px;
        text-align: center;
        border-radius: 4px;
        font-weight: 400 !important;
        font-size: 14px;
        background-color: #f28c34 !important;
        color: #fff !important;

        span {
          font-weight: 400 !important;
          color: #fff !important;
        }
        &:hover {
          background-color: #f28c34 !important;
          color: #fff !important;
          span {
            color: white !important;
          }
        }
      }
      .mrket-img {
        height: 30px;
      }

      .cell {
        .coin-content {
          display: inline-block;
          .coin-img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
          }
        }
        .red {
          color: #c61b3d;
        }
        .green {
          color: #03ad79;
        }
        .active {
          color: #c61b3d;
          font-size: 18px;
          margin-top: 4px;
          margin-left: -3px;
        }
        .el-icon-star-on,
        .el-icon-star-off {
          text-align: left;
          cursor: pointer;
          margin-right: 10px;
        }
        p,
        span,
        code {
          color: #1f2533;
          // font-weight: 600;
        }
        .el-table__expand-icon {
          display: none;
        }
        .dark-color {
          color: #9aa1b1;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .table-expand {
      min-height: 228px;
      padding: 10px;
      display: flex;
      cursor: auto;
      .expand-detail {
        flex: 1;
        display: inline-block;
        margin-right: 32px;
      }
      .el-form {
        display: inline-block;
        width: 300px;
        .el-form-item {
          margin-bottom: 0px;
          padding: 0 20px;
          .el-form-item__label {
            padding: 0;
          }
          &:nth-child(2n + 1) {
            background-color: #f5f5f7;
          }
        }
        .el-form-item__content {
          display: inline-block;
          float: right;
        }
      }
    }
  }
  .el-tabs__item {
    font-size: 16px;
    margin-bottom: 10px;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #bec1c8 !important;
    font-weight: 500;
  }
}
.home-content-partners {
  width: 100%;
  padding-bottom: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .hcp-title {
    width: 1200px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    .ht-main {
      font-size: 36px;
      font-weight: bold;
      color: #15192a;
    }
    .ht-sub {
      margin-top: 18.5px;
      font-size: 18px;
      color: #15192a;
    }
  }
  .hcp-content {
    width: 1200px;
    margin: 0 auto;
    padding: 25px 0;
    background-color: #f4f4f5;
    display: flex;
    justify-content: center;

    .hc-imgs {
      width: 227px;
      height: 122px;
      display: flex;
      flex-wrap: wrap;
      background: #fff;
      &.V2 {
        margin-left: 16px;
      }
      .img-c {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
