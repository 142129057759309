<template>
  <div>
    <ueex-home-content></ueex-home-content>
  </div>
</template>

<script>
import ueexHomeContent from "./ueex-home-content.vue";

import { mapActions, mapGetters } from "vuex";
import { VUE_APP_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";
export default {
  components: { ueexHomeContent },

  data() {
    return {
      VUE_APP_ID,
    };
  },
  computed: {
    ...mapGetters({
      darkMode: "user/darkMode",
    }),
  },
  created() {},
};
</script>

<style></style>
